<template>
  <div>

    {{
      row.current_stage
        ? $companyConfiguration.cardStages.getStageShowName(row.current_stage.type)
        : ''
    }}

  </div>
</template>

<script>

export default {
  name: "current-stage-column-template",
  components: {},

  props: {
    row: {type: Object, required: true}
  },

  data() {
    return {}
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {},

}
</script>

<style>
.table-router-link {
  text-decoration: none;
}
</style>